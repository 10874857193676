import React, { useEffect } from 'react';
import './about.css';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Sreya from "../photos/sreya.jpg"
import Kavya from "../photos/kavya.jpg"
import {Container} from "react-bootstrap";
import ReactGA from "react-ga";
 
function About({image}) {

    useEffect(() => {

        ReactGA.pageview("/about")
    }, [])
    
  return (
    <Container className = "aboutcont">
            <Link to = "/" className = "back"> Back </Link>
            <div className = "top">

            <h3 className = "intro"> About Map Wrapped</h3>
            <p className = "detail"> This is just a personal project that we made together inspired by a love for traveling and creating interesting products. 
            Got any suggestions? <i> <a target = "_blank" href= "https://forms.gle/tbMuPZPFNFPJ7UUU6"> Feedback Form </a> </i> Wanna donate? Venmo <i> @Avi_Garg</i>  </p>
            </div>
            <div className = "users">
                <a href = "http://www.avigarg.me" target = "_blank" rel = "noreferrer">
                <div className = "profile">
                    <img src = "http://www.avigarg.me/photos/Profile.jpeg" />

                     <p> Avi Garg </p>
                </div>
                </a>
                <a href = "https://www.sreyac.me/" target = "_blank" rel = "noreferrer">
                <div className = "profile">
                    <img src = {Sreya} />

                    <p> Sreya Cherukuri </p>
                </div>
                </a>
                <a href = "https://www.linkedin.com/in/kavyajawabnavis/" target = "_blank" rel = "noreferrer">
                <div className = "profile">
                    <img src = {Kavya} />

                    <p> Kavya Jawabnavis </p>
                </div>
                </a> 
                <p> And thanks to all the people who gave their feedback and ideas to this including: Tanya Kapur, Andrew Zhang, Tara Bhatia, Archana Yarlagadda, 
                   Jeremy Lee, Sonali Loomba, Tanya Matthew, Tarra O'Malley, and Nehal Garg</p>
            </div>


    </Container>
  );
}

const mapStateToProps = state => ({
    image: state.home.image, 
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(About);
