import React, { useEffect, useState } from 'react';
import './home.css';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Container, Button} from "react-bootstrap";
import Map from "../photos/map.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faMapMarkerAlt, faTimes, faHome, faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Loader } from "@googlemaps/js-api-loader"
import axios from "axios";
import {endpoint} from "../endpoint";
import ReactGA from "react-ga"
import {setImage, setLocations, setMap, setCity, setColor} from "./homeSlice";
import {encode} from "base64-arraybuffer"
import Wrapped1 from "../photos/wrapped_1.jpg";
import Wrapped2 from "../photos/wrapped_2.jpg";
import Wrapped3 from "../photos/wrapped_3.jpg";
import Wrapped4 from "../photos/wrapped_4.jpg";
import Wrapped5 from "../photos/wrapped_5.jpg";
import Wrapped6 from "../photos/wrapped_6.png";



function Home({image, setImage, locations ,
    setLocations, setMap, map, color, city, setCity, setColor }) {
    const loader = new Loader({
        apiKey: "AIzaSyAPJ1dxHz_2Wc4Uavu7-OzFkq8B-hn5LTs",
        version: "weekly",
        libraries: ["places"]
      });


    const [search, setSearch] = useState("")
    const [updating, setUpdating] = useState(null)
    const [first, setFirst] = useState(true)


    const image_array = [Wrapped1, Wrapped2, Wrapped3, Wrapped4, Wrapped5, Wrapped6]

    useEffect(() => {

        ReactGA.pageview("/home")
    }, [])

    useEffect(() => {
        loader.load().then((google) => {
            var input = document.getElementById("searchbox")
            console.log(input.element)
            var defaultBounds = new google.maps.LatLngBounds(
                new google.maps.LatLng(-124.8902, 24.3963),
                new google.maps.LatLng(-66.88544, 49.384));
            const options = {
                bounds: defaultBounds,
                componentRestrictions: { country: ["us", "mx"] },
                fields: ["address_components"],
                strictBounds: false,
                types: ["(cities)"]
            };

            
            const autocomplete = new google.maps.places.Autocomplete(input, options )
            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace(); 
                const address_comps = place["address_components"]
                var loc_array = new Array();
                address_comps.forEach(address => loc_array.push(address["short_name"]))
                
                setLocations([...locations, loc_array])
                setUpdating(null)
                setSearch("");

            })
            // var searchBox = new google.maps.places.SearchBox(input, {bounds: defaultBounds})

            // searchBox.addListener("places_changed", () => {
            //     const places = searchBox.getPlaces()
            //     var loc_array_array = new Array();
            //     places.forEach(place => {
            //         var loc_array = new Array();
            //         const address_comps = place["address_components"]
            //         address_comps.forEach(address => loc_array.push(address["short_name"]))
            //         loc_array_array.push(loc_array)
        
            //     })
            //     setLocations([...locations, ...loc_array_array])
            //     setUpdating(null)
            //     setSearch("");
            // })
        })
    })


    function changeCity() {
        setCity(!city)
        setUpdating(null)
    }

    function changeColor() {
        setColor(!color)
        setUpdating(null)
    }


    function FilterIndex(index_2) {
        const new_locations = locations.filter((_, index) => index !== index_2)
        setLocations(new_locations)
    }

    function GenerateWrapped() {
        ReactGA.event({
            category: "click", 
            action: "generating", 
            label: "Generating", 
            value: map
        })

        setUpdating(true)
        axios.post(endpoint + "/generate/", {cities: locations, map: map, city: city, color: color}, {responseType: "arraybuffer"}).then(response => {
            
            setImage("data:image/png;base64," + encode(response["data"]))
            setUpdating(false)

        }).catch(data => {
            setUpdating("failed")
        })
    }

    const location_icons = locations.map((location, index) => {

        let icon; 
        if (index === 0){
            icon = faHome
        } else {
            icon = faMapMarkerAlt
        }

        return (
        <div className = "location" key = {index}>
            <FontAwesomeIcon className = "locicon" icon = {icon} />
            <p> {location[0]}, {location.length > 2 && location[2]} </p>
            <FontAwesomeIcon className = "smallicon" icon = {faTimes} onClick = {() => FilterIndex(index)} />
        </div>)
    })

  return (
    <Container className = "home">
        <div className = "locationSec">

            <h3 className = "intro"> where did you travel in 2022? </h3>
            <p className = "detail"> Mark all your locations from this year to see your travel wrapped for the year!  </p>

            {first && <label> Choose Map </label>}
            {first && <div className = "imagecont">
                <FontAwesomeIcon icon = {faArrowLeft} className = "arrow" onClick = {() => setMap((map - 1 + image_array.length) % image_array.length) } />
                <img src = {image_array[map]} alt = "potential map"/>
                <FontAwesomeIcon icon = {faArrowRight} className = "arrow" onClick = {() => setMap((map + 1) % image_array.length) }/>
                </div> 
            }
            {first && 
            <div className = "otherselections">
                <label> Color Range? </label>
                <input 
                    type = "checkbox"
                    checked = {color}
                    onChange = {() => changeColor(!color)}
                />
            </div> 
            
            }

            {locations.length === 0 && !first &&  <label> Enter Hometown </label>}
            {locations.length > 0 && !first && <label> Enter Destinations </label>}
            
            {!first && 
            <div className = 'searchcont'>
            <FontAwesomeIcon id = "searchicon" icon = {faSearch} size = "1x" />
            <input 
                id = "searchbox"
                placeholder = { "Search"}
                value = {search}
                type = "text"
                disabled = {locations.length > 20}
                onChange = {e => setSearch(e.target.value)}
            />
            </div> }

            {!first && 
            <div className = "icons">
                {location_icons}
            </div>}
            {location_icons.length > 0 && !first &&  updating == null &&  <p  className = "gen generate" onClick = {() => GenerateWrapped()}> GENERATE WRAPPED  </p>}
            {location_icons.length > 0 && !first && updating == true &&  <p className = "gen generating"> GENERATING </p>}
            {updating === "failed" && !first && <p className = "failed" onClick = {() => GenerateWrapped()}> Try Again </p>}
            {updating == false && !first && <Link to = "generated" className = "gen generated"> SEE TRAVEL WRAPPED </Link> }

            {first && <Button className = "moveBtn" onClick = {() => setFirst(false)}> Go Next </Button>}
            {!first && <Button className = "moveBtn" onClick = {() => setFirst(true)}> Go Back </Button>}
            <Link to = "/about" className = "about"> About </Link>
   
        </div> 

        <img src = {Map} alt = "U.S Map" />

    </Container>
  );
}

const mapStateToProps = state => ({
    image: state.home.image, 
    locations: state.home.locations, 
    map: state.home.map, 
    city: state.home.city,
    color: state.home.color 
})

const mapDispatchToProps = {setImage, setLocations, setMap, setCity, setColor}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
