import React from 'react';
import './App.css';
import {Routes, Route} from "react-router-dom";
import ReactGA from "react-ga";
import Home from "./components/home/home";
import Generated from "./components/generated/generated";
import About from "./components/about/about";
function App() {

  ReactGA.initialize("UA-166016248-6")
  return (
    <Routes>
        <Route path = "/" element = {<Home/>} ></Route>
        <Route path = "/generated" element = {<Generated/>}></Route>
        <Route path = "/about" element = {<About/>}></Route>
    </Routes>
  );
}

export default App;
