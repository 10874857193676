import React, { useEffect } from 'react';
import './generated.css';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Container} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave  } from "@fortawesome/free-solid-svg-icons";
import {faInstagram} from "@fortawesome/free-brands-svg-icons"
import ReactGA from "react-ga";
 
function Generate({image}) {

    useEffect(() => {

        ReactGA.pageview("/home")
    }, [])
    

    function LogDownload() {
        ReactGA.event({
            category: "click", 
            action: "downloading", 
            label: "Download"
        })
    }
  return (
    <Container className = "generatedcont">
        <Link to = "/" className = "goback"> Go Back </Link> 
        <p> <FontAwesomeIcon icon = {faSave}> </FontAwesomeIcon> Press and hold and then click save on the pop-up </p> 
        <p> <FontAwesomeIcon icon = {faInstagram}> </FontAwesomeIcon> Press and hold and click more, then Instagram to post on story.</p> 

        <a href = {image} onClick = {() => LogDownload()} target = "_blank" rel = "noreferrer" download = "wrapped.png" className = "wrapped"> <img  src = {image} /> </a>

    </Container>
  );
}

const mapStateToProps = state => ({
    image: state.home.image, 
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Generate);
