import { createSlice } from '@reduxjs/toolkit';

export const homeSlice = createSlice({
  name: 'home',
  initialState: {
    image: null,
    locations: [], 
    map: 0, 
    city: false, 
    color: false
  },
  reducers: {
    setImage: (state, action) => { 
        state.image = action.payload;
    }, 
    setLocations: (state, action) => {
        state.locations = action.payload
    },
    setMap: (state, action) => {
        state.map = action.payload;
    }, 
    setCity: (state, action) => {
      state.city = action.payload
    }, 
    setColor: (state, action) => {
      state.color = action.payload  
    }
  },
});

export const { setImage, setLocations, setMap, setCity, setColor } = homeSlice.actions;



export default homeSlice.reducer;
